<template>
  <div class="main">
      
      <el-container style="height: 100%;">
          <el-header class="header" id="headerbox">
              <el-row style="z-index: 2;">
                  <el-col :span="6">
                      <div class="titlediv">
                          <span class="title">{{ title }}</span>
                          
                      </div>
                  </el-col>
                  <el-col :span="12">
                      <div></div>
                  </el-col>
                  <el-col :span="18">
                      <div class="user">
                          <span class="user-span">你好：{{user.realName}}</span>
                          <el-button v-if="user.totpFlag" circle  @click="showQrcode()" style="font-size:20px;border:none;background-color: transparent;color:white;padding: 0px;"><svg-icon icon-class="qrcode" style="width: 35px;height: 35px; vertical-align: middle;"/></el-button>
                          <el-button  @click="logout()" style="font-size:20px;border:none;background-color: transparent;color:white;padding: 0px;padding-right:10px"><span class="outBtn">退出登录</span>
                            <!-- <svg-icon icon-class="close" style="width: 35px;height: 35px; vertical-align: middle;"/> -->
                          </el-button>
                          <el-dropdown trigger="click" @command="handleCommand">
                            <!-- <el-button  circle style="font-size:20px;border:none;background-color: transparent;color:white;padding: 0px;"><span class="styleBtn">更换主题</span> -->
                              <!-- <svg-icon icon-class="close" style="width: 35px;height: 35px; vertical-align: middle;"/> -->
                            <!-- </el-button> -->
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item v-for="o in headerData" :key="o.name" :command="{data:o.data,name:o.name,background:o.background}">{{o.name}}</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                      </div>
                  </el-col>
              </el-row>
              <div id="selectBox"></div>
          </el-header>
          <el-main style="padding: 100px 0px 0px 0px;">
            <div style="position:absolute;width: 100%;height: 80%;">
                <el-row :gutter="20" style="width:100%;height: 100%;padding-top: 50px;margin-bottom: 20px;" type="flex" justify="center">
                    <el-col :span="13" style="height: 95%;">
                        <div class="bg-purple" style="height: 100%">
                            <System></System>
                        </div>
                    </el-col>
                    <el-col :span="6" style="height: 95%;">
                        <div class="bg-purple" style="height: 100%;">
                            <Notice></Notice>
                            <div style="height: 3%;"></div>
                            <FileList></FileList>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <img class="bgimg" :src="bgimg" style="z-index:-1"/>
          </el-main>
      </el-container>
      <Live2d></Live2d>
  </div>
  
</template>
<script>
import bgimg from "@/assets/img/bg-blue.png"
import {sendPost} from '@/api/api'
import System from "@/components/main/System.vue"
import FileList from "@/components/main/FileList.vue"
import Notice from "@/components/main/Notice.vue"
import Live2d from '../live2d/Live2d.vue'
import {initHeader} from "../header/index.js"

export default {
  data() {
      return {
          bgimg:bgimg,
          title:process.env.VUE_APP_TITLE,
          user:{realName:'user',qrcode:"",totpFlag:false},
          headerData:[
            {
              name:"猫头鹰",
              data:"2023-10-26",
              background:"bg1.jpg"
            },{
              name:"松鼠",
              data:"2023-11-17",
              background:"bg0.jpg"
            },
            {
              name:"北极光",
              data:"2023-12-12",
              background:"bg2.jpg"
            },{
              name:"包饺子",
              data:"2024-02-01",
              background:"bg3.jpg"
            }
          ]
      }
  },
  components:{
    System:System,
    FileList:FileList,
    Notice:Notice,
    Live2d:Live2d,
  },
  mounted(){
    this.getUser();
    // this.initHeader();
  },
  methods:{
      logout(){
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sendPost(null,"/sso/signout").then((res) => {
            if(res.data.code == '200'){
              this.$router.push('/login');
            }
          })
        }).catch(() => {});
      },
      getUser(){
        sendPost(null,"/sso/getInfo").then((res) => {
            if(res.data.code == '200'){
               this.user = res.data.data;
            }
        })
      },
      showQrcode(){
        this.$confirm('<strong> <img src='+this.user.qrcode+' style="width:300px;" alt="">'+
          '<p>请通过<span style="color:red">手机OTP应用(如FreeOtp)</span>扫码绑定</p></strong>', "动态口令绑定二维码", {
          dangerouslyUseHTMLString: true,
          // confirmButtonText: '下载图片',
          // cancelButtonText: "关闭",
          showConfirmButton: false,
          closeOnClickModal: false,
          center:true,
        }).then(() => {
          // this.downloadBaes64File(response.data,row.userName+'_otp');
        }).catch(() => {});
      },
      initHeader(){
        const data = require('@/assets/header/'+this.headerData[0].data+"/data.json");
        this.bgimg = require('@/assets/img/'+this.headerData[0].background);
        initHeader(data);
      },
      handleCommand(command) {
        const data = require('@/assets/header/'+command.data+"/data.json");
        this.bgimg = require('@/assets/img/'+command.background);
        initHeader(data);
        
      }
  }
}
</script>

<style>
.main{
  width:100%;
  height: 100%;
}
.bgimg{
  position:fixed;
  width: 100%;
  height: 100%;
  left: 0;
}
.header{
  position:fixed;
  height:50px !important;
  /* background-color: rgba(0, 0, 0, 1); */
  width: 100%;
  background-size: cover;
  background-image: url('../assets/img/header-blue.png');
}
.logo{
  position: absolute;
  top:20px;
  left:50px;
}
.titlediv{
    float:left;
    color:white;
    /* background-color: #00000061; */
    border-radius:20px;
    margin-top:10px;
    width:450px;
}
.titlediv:hover{
  color: rgb(255, 187, 0);
  text-shadow: 0 0 15px rgba(212, 27, 14, 0.9);
}
.title{
  height:70px;
  line-height: 70px;
  font-size: 30px;
}
.user-span{
  font-size: 20px;
  padding-right:10px;
}
.user{
  color:white;
  text-align: center;
  float: right;
  /* background-color: #00000061; */
  border-radius:20px;
  margin-top:10px;
  padding: 10px;
  /* width: 400px; */
}
.bg-purple {
        border-radius: 10px;
    }

.el-col {
        border-radius: 10px;
    }    
    .box-card{
        margin-top: 20px;
        /* border-radius: 10px; */
        /* text-align: left; */
    }
    .el-card{
        /* border-radius: 10px; */
        text-align: left;
        background-color: rgba(255, 255, 255, 0.9);
        border:0px;
        border-radius: 10px;
    }
    .el-carousel{
        border-radius: 10px;
    }
    .el-card__header{
        padding: 10px 20px;
        /* border: none !important; */
    }
    .outBtn:hover{
      color: rgb(255, 136, 0);
      text-shadow: 0 0 15px rgba(212, 27, 14, 0.9);
    }
    .styleBtn:hover{
      color: rgb(0, 204, 255);
      text-shadow: 0 0 15px rgba(212, 27, 14, 0.9);
    }
</style>
<!-- <style src="../header/index.css"></style> -->
<style src="@/assets/header/index.css"></style>
<style src="@/assets/header/normalize.css"></style>