<template>
    <el-card shadow="hover" style= "height: 100%;" v-loading="loading">
              <div slot="header" class="clearfix">
                  <!-- <i class="el-icon-star-off"></i> -->
                  <span class="tag4"></span>
                  <span> 系统列表</span> 
                  <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                  <el-button class="icon-btn" icon="el-icon-refresh-left" type="text" @click="getApplications()"></el-button>
              </div>
              <el-row :gutter="20" style="padding-top: 20px;">
                    <el-col :span="6"  v-for="o in applications" :key="o.applicationId"  align="middle">
                        <div :class="{'application-row':o.status=='0','application-row-disable':o.status=='1'}" :style="{'background-color': (o.status=='0'?o.color:'#e4e4e4')}" @click="turnApplication(o)">
                            <svg-icon :icon-class="o.icon" class="application-icon"></svg-icon>
                        </div>
                        <span class="application-text">{{o.applicationName}}</span>
                    </el-col>
              </el-row>
          </el-card>          
  </template>
  
  <script>
  import {sendPost} from '@/api/api'
  export default {
      data() {
          return {
              loading:false,
              applications: [
                  
              ]
          }
      },
      methods: {
          getApplications(){
              this.loading = true
              sendPost(null,"/sso/index/application").then((res) => {
                  this.loading = false
                  if (res.data.code=='200') {
                      this.applications = res.data.data;
                  } else {
                      this.$message({
                          message: "查询系统列表失败",
                          type: "error",
                      })
                  }
              })
              .catch((err) => {
                  this.loading = false
              })
          },
          turnApplication(param){
            if(param.status == '1'){
                this.$alert('<p><span style="color:red">【'+param.applicationName+'】</span>暂时无法访问，您可联系</p><p><span style="color:red">【'+param.name+'('+param.phone+')】</span>了解详细原因！</p>', '提示', {
                    confirmButtonText: '确定',
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                });
            }else if(param.isFrame == 'Y'){
                this.loading = true
                window.open(param.path, '_blank');
            }else{
                this.loading = true
                window.location.href = param.path;
            }
          }
      },
      mounted() {
          this.getApplications();
      },
  }
  </script>
  
  <style>
      .tag4{
        background-color: rgb(0, 102, 255);
        padding-right: 8px;
        margin-right: 5px;
      }
      .application-row{
        border:2px solid #e4e4e4;
        box-shadow: 0 0 15px #e4e4e4;
        /* border:2px solid rgba(70, 212, 14, 0.7); */
        /* box-shadow: 0 0 15px rgba(70, 212, 14, 0.7); */
        background-color: rgba(221, 196, 226, 0.9);
        border-radius: 100px;
        margin-bottom: 20px;
        height: 150px;
        width: 150px;
        transition: transform 0.3s ease;
      }
      .application-row-disable{
        border:2px solid #e4e4e4;
        box-shadow: 0 0 15px #e4e4e4;
        background-color: #e4e4e4;
        border-radius: 100px;
        margin-bottom: 20px;
        height: 150px;
        width: 150px;
        transition: transform 0.3s ease;
      }
      .application-row:hover{
        border:2px solid rgba(70, 212, 14, 0.9);
        box-shadow: 0 0 15px rgba(70, 212, 14, 0.9);
        /* border-color: rgb(43, 226, 202) !important; */
        /* box-shadow: 0 0 15px rgb(43, 226, 202) !important; */
        transform: scale(1.1) !important;
      }
      .application-row-disable:hover{
        border-color:rgb(226, 110, 43) !important;
        box-shadow: 0 0 15px rgb(226, 110, 43) !important;
        transform: scale(1.1) !important;
      }
      .application-icon{
        height: 150px  !important;
        width: 80px !important;
      }
      .application-text{
        font-size:18px;
        font-weight: bolder;
        /* text-shadow: 0 0 3px #ff8d85; */
      }
  </style>