<template>
<div v-show="mainflag">
    <!-- <div class="tool-div">
		<button class="tool-button" @click="sendDataA">一句一言</button>
        <button class="tool-button" @click="sendDataB">笑话</button>
        <button class="tool-button" @click="sendDataC">情话</button>
	</div> -->
    <div class="chat-div">
		<textarea class="chat-area" id="msg" v-model="str" placeholder="输入对话内容..."></textarea>
		<button class="chat-button" @click="sendData">发送</button>
	</div>
    <canvas id = "live2d"></canvas>
    <div class="message"></div>
    <div class="controll" id="controll" @mousedown="mousedown($event)"></div>
</div>
</template>
<script>
import {initModel,initMessage,initSocket,send, doVoice} from './live2d.js'
import {sendPost} from '@/api/api'

// const cssurl = 'http://127.0.0.1/live2d/models/ariu/ariu.css';
// const url = 'http://127.0.0.1/live2d/models/ariu/ariu.model3.json';
// const msgUrl = 'http://127.0.0.1/live2d/data/message.json';
// const scale = 0.075;
// let voiceName = "zh-CN-XiaoyiNeural";
let wsUrl = "ws://localhost:81/dev-api/websocket"
// const width = 300;
// const height = 450;
// const width = 260;
// const height = 300;
let width = 250;
let height = 460;
let isVoice = false;
let siteId = null;
let voiceName = "";
export default {
    data() {
        return {
            speech:null,
            str:null,
            // websocketUrl:wsUrl,
            msg:null,
            // username:"admin",
            DragEl: null, //拖动元素
            totalHeight: null,
            totalWidth: null,
            // 位置差
            disX: 0,
            disY: 0,
            mainflag: false,
        }
    },
    mounted(){
        this.$nextTick(() => {
            sendPost(null,"/sso/index/getLive2dConfig").then((res) => {
                if(res.data.code == '200'){
                    var config = res.data.data;
                    this.mainflag = config.showFlag;
                    if(config.showFlag){
                        width = config.width;
                        height = config.height;
                        isVoice = config.voiceFlag;
                        siteId = config.siteId;
                        voiceName = config.voiceName
                        // wsUrl = "ws://"+window.location.host+process.env.VUE_APP_BASE_API+"/websocket"
                        // voiceName = config.voiceName;
                        this.loadCss(config.style);
                        initModel(config.url,config.scale,config.width,config.height,config.voiceFlag).then();
                        // initSocket(wsUrl,this.username,config.voiceName);
                        if(config.actionFlag){
                            initMessage(config.action,config.voiceFlag);
                        }

                        $('.chat-div').css('bottom',parseInt(height)+150);
                        $('.message').css('bottom',parseInt(height)+70);
                    }
                }
            });
            // getUserconfig().then(response => {
            //     var config = response.data;
            //     this.mainflag = config.showFlag;
            //     if(config.showFlag){
            //         width = config.width;
            //         height = config.height;
            //         isVoice = config.voiceFlag;
            //         siteId = config.siteId;
            //         voiceName = config.voiceName
            //         wsUrl = "ws://"+window.location.host+process.env.VUE_APP_BASE_API+"/websocket"
            //         // voiceName = config.voiceName;
            //         this.loadCss(config.style);
            //         initModel(config.url,config.scale,config.width,config.height,config.voiceFlag).then();
            //         initSocket(wsUrl,this.username,config.voiceName);
            //         if(config.actionFlag){
            //             initMessage(config.action,config.voiceFlag);
            //         }

            //         $('.chat-div').css('bottom',parseInt(height)+150);
            //         $('.message').css('bottom',parseInt(height)+70);
            //     }
            // });
            // this.websocketInit();
            this.DragEl = document.getElementById("controll");
            this.totalHeight = window.innerHeight;
            this.totalWidth = window.innerWidth;
        });
    },
    methods: {
        sendData: function(){
            // this.speak(this.str);
            // var data = {"fromuser":this.username,"method":"chat","isVoice":"true","message":this.str};
            // var data = {"fromuser":this.username,"method":"speak","isVoice":"true","voiceName":voiceName,"message":this.str};
            
            //websocket方式
            // var data = {};
            // data.message  = this.str;
            // data.siteId = siteId;
            // data.method = "chat";
            // send(data,isVoice);
            //websocket方式结束

            //HTTP方式
            var data = {};
            data.query  = this.str;
            data.siteId = siteId;
            data.onlyQuestion = false;
            data.no = 1;
            data.isVoice = isVoice;
            data.voiceName = voiceName;
            sendPost(data,"/sso/index/faq").then((res) => {
                if(res.data.code == '200'){
                    var msg = {};
                    console.log(msg);
                    msg.message = res.data.data[0].answer;
                    msg.voice = res.data.data[0].voice;
                    msg.expression = null;
                    
                    doVoice(msg);
                    this.str = null;
                }
            });
            // faq(data).then(response => {
            //     var msg = {};
            //     msg.message = response.data.rows[0].answer;
            //     msg.voice = response.data.rows[0].voice;
            //     msg.expression = null;
            //     doVoice(msg);
            //     this.str = null;
            // });
        },
        initMessage: function (messagePath){
            //初始化函数
            if (messagePath != undefined) {
                
                $.ajax({
                    cache: true,
                    url: messagePath,
                    dataType: "json",
                    success: function (result) {
                        this.msg = result;
                        // alert(result)
                        // console.log(result)
                        initMessage(result,isVoice)
                    }
                });
            }

        },
        loadCss(url){
            let element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("href", url);
            document.head.appendChild(element);
        },
            mousedown(event) {
            // 1,计算位置差
            // 因为clientX和offsetLeft的属性返回的位置不一样 要相减得到鼠标在拖动元素内实际点击的位置
            // 后面每一次拖动时都要减去这个差值 否则就会造成你拖动的位置一直都是元素的左上角 而不是你之前点击的位置
            this.disX = event.clientX - this.DragEl.offsetLeft;
            this.disY = event.clientY - this.DragEl.offsetTop;

            //2， 获取拖动元素的高度和容器的高度 为了下面进行限制元素拖动的范围
            let dragHeight = this.DragEl.offsetHeight;
            let dragWidth = this.DragEl.offsetWidth;

            // 添加鼠标移动事件
            document.onmousemove = (el) => {
            // 3，获取鼠标移动位置
            let moveX = el.clientX - this.disX;
            let moveY = el.clientY - this.disY;
            // 4，限制拖动
            //控制范围：在元素 被拖拽的过程中 判断 元素的定位值 是否到达边界 如果到了 就不能在走了

            //4.1第一种 限制范围的判断
            // if(moveX <=0 || moveY <=0){ // 控制上边界和左边界
            //   return
            // }
            // if(moveX >= dragContainerWidth - dragWidth || moveY >= dragContainerHeight - dragHeight){
            //   return
            // }

            // 4.2 第二种限制方位的判断 建议使用第二种； 第一种靠边时经常会有边距，不太丝滑
            // 左边界
            if (moveX <= 270) {
                moveX = 270;
            }
            // 右边界 减去的值为div的宽度
            if (moveX >= this.totalWidth-50) {
                moveX = this.totalWidth-50 ;
            }
            // 上边界
            if (moveY <= 0) {
                moveY = 0;
            }
            // 上边界减去的值为div的高度
            if (moveY >= this.totalHeight-50) {
                moveY = this.totalHeight-50;
            }
            // 5, 开始移动
            this.DragEl.style.left = moveX + "px";
            this.DragEl.style.top = moveY + "px";
            $('#live2d').css('left',moveX-width+50);
            $('#live2d').css('top',moveY-height);
            $('.message').css('left',moveX-230);
            $('.message').css('bottom',this.totalHeight-moveY+parseInt(height)+20);
            $('.chat-div').css('left',moveX-230);
            $('.chat-div').css('bottom',this.totalHeight-moveY+parseInt(height)+100);
            //   $('.tool').css('left',moveX-250+50);
            //   $('.tool').css('bottom',this.totalHeight-moveY-50);

            };
        /* 6，鼠标抬起解除事件 */
            document.onmouseup = () => {
                document.onmousemove = null;
            };
        },
    }, 
}
</script>
<style>

</style>
