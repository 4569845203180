<template lang="">
    <el-card style="height:49%;position:relative" :body-style= "{ padding: '10px 30px 10px 30px'}" >
        <div slot="header" class="clearfix">
            <span class="tag"></span><span>文件下载</span>
            <el-button class="icon-btn" icon="el-icon-refresh-left" type="text" @click="getHotSearchInfo(true,true,true)"></el-button>
        </div>
        <div v-for="(o,index) in contentList" :key="o.contentId" class="hot-item">
            <el-link target="_blank" :underline="false" @click="getDetail(o)"><span :class="setColor(index+1)">&nbsp;{{index+1}}&nbsp;</span><span style="color:black;font-size:16px">{{o.title}}</span></el-link>
        </div>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="pageInfo.pageNumber"
            :page-size="pageInfo.pageSize"
            layout="total, prev, pager, next"
            :hide-on-single-page="true"
            :total="pageInfo.totalNumber">
        </el-pagination>
        <el-dialog title="通知公告详情" :visible.sync="dialogVisible" :fullscreen="false">
            <div >
                <h3 class="article_h3">{{contentDetail.title}}</h3>
                <p class="small_title">发布时间：{{contentDetail.publishDate}}</p>
                <el-table
                    :data="contentDetail.fileList" style="width: 100%">
                    <el-table-column
                        prop="fileName"
                        label="文件名">
                    </el-table-column>
                    <el-table-column
                        prop="fileType"
                        label="文件类型">
                    </el-table-column>
                    <el-table-column
                        prop="fileSize"
                        label="文件大小">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        align="center"
                        class-name="small-padding fixed-width"
                    >
                        <template slot-scope="scope">
                            <span class="btn-cell-wrap">
                                <el-button
                                size="small"
                                type="text"
                                icon="el-icon-download"
                                @click="handleDownload(scope.row)"
                                >下载</el-button>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </el-card>
</template>
<script>
import {sendPost} from '@/api/api'
import { saveAs as FileSaver } from 'file-saver'

export default {
    data() {
        return {
            loading:false,
            dialogVisible:false,
            pageInfo:{
                pageNumber: 1,
                pageSize: 6,
                totalNumber: 0
            },
            contentList:[],
            contentDetail:{
                title: '标题',
                linkFlag: 'N',
                redirectUrl: '',
                publishDate: 'yyyy-MM-dd',
                fileList: [],
            }
        }
    },
    methods: {
        getFile(){
            this.loading = true
            sendPost(this.pageInfo,"/sso/index/getFile").then((res) => {
                  this.loading = false
                  if (res.data.code=='200') {
                    this.pageInfo = res.data.data.pageInfo;
                    this.contentList = res.data.data.contentList;
                  } else {
                      this.$message({
                          message: "查询通知公告列表失败",
                          type: "error",
                      })
                  }
              })
              .catch((err) => {
                  this.loading = false
              })
            
            // console.log("111"+result)
            // this.tit = sendPost({"id":1,"content":"123"},"selectTest")
        },
        handleCurrentChange(val){
            this.pageInfo.pageNumber = val;
            this.getNotice();
        },
        setColor(rank){
            if(rank==1){
                return "color1"
            }else if(rank==2){
                return "color2"
            }else if(rank==3){
                return "color3"
            }else{
                return "color10"
            }
        },
        getDetail(param){
            sendPost(param,"/sso/index/getDetail").then((res) => {
                  if (res.data.code=='200') {
                    this.contentDetail = res.data.data;
                    if(this.contentDetail.linkFlag == 'Y'){
                        window.open(this.contentDetail.redirectUrl, '_blank');
                    }else{
                        this.dialogVisible = true;
                    }
                  } else {
                      this.$message({
                          message: "查询通知公告列表失败",
                          type: "error",
                      })
                  }
              })
              .catch((err) => {
              })
        },
        handleDownload(row){
            const a = document.createElement('a');
            a.target="_blank";
            a.href = row.src;
            a.download = row.fileName; // 下载后文件名
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click(); // 点击下载
            document.body.removeChild(a);
            // this.$download(row.src,row.fileName);
            // const oReq = new XMLHttpRequest()
            // const URL= '' // URL 为下载的URL地址
            // FileSaver.saveAs(row.src, "111.doc")
            // const oReq = new XMLHttpRequest();
            // oReq.open('GET', row.src, true);
            // oReq.responseType = 'blob';
            // alert(11)
            // oReq.onload = function() {
            // const file = new Blob([oReq.response], {
            //     type: 'blob'
            // })
            // FileSaver.saveAs(file, row.fileName) // fileName为文件名
            // }
        }

    },
    mounted() {
        this.getFile();
    },
}
</script>
<style>
    .clearfix{
        text-align: left;
        font-size: 18px;
        font-weight: 500;
    }
    .icon-btn{
        float: right;
        color:#303133;
        font-size:20px;
        padding: 0;
    }
    .tag{
        background-color: green;
        padding-right: 8px;
        margin-right: 5px;
    }
    .hot-item{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        margin-top: 10px;
    }
    .color1{
        background-color: #FF5722;
        color: #fff;
        border-radius: 2px;
        font-size: 18px;
        margin-right: 6px;
    }
    .color2{
        background-color: #1E9FFF;
        color: #fff;
        border-radius: 2px;
        font-size: 18px;
        margin-right: 6px;
    }
    .color3{
        background-color: #FFB800;
        color: #fff;
        border-radius: 2px;
        font-size: 18px;
        margin-right: 6px;
    }
    .color10{
        background-color: #FAFAFA;
        border-radius: 2px;
        font-size: 18px;
        margin-right: 6px;
    }
    .el-pager li{
        background-color: #fafafa00;
    }
    .el-pagination .btn-next{
        background-color: #fafafa00;
    }
    .el-pagination .btn-prev{
        background-color: #fafafa00;
    }
    .el-pagination button:disabled{
        background-color: #fafafa00;
    }
    .el-pagination{
        position: absolute;
        bottom:20px
    }
    .article_h3{
        font-size: 28px;
        color: #333333;
        text-align: center;
        margin-top: 0px;
    }
    .small_title{
        padding-bottom: 10px;
        font-size: 14px;
        color: #333;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
</style>