import axios from 'axios'
import Cookies from "js-cookie";

const defaultConfig = {
  timeout: 5 * 1000,
  baseURL:process.env.VUE_APP_BASE_API,  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！
};
export function sendPost(data,url){
  axios.defaults.headers.post['satoken'] = localStorage.getItem('satoken');
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  return axios.post(url, data,defaultConfig).then((result) => {
    const msg = result.data.msg || "";
    if(result.data.code == '500' && (msg.startsWith("token 无效") || msg.startsWith("token 已被顶下线"))){
      Cookies.remove('satoken');
      location.href = process.env.VUE_APP_PATH + '#/login';
    }else{
      return result;

    }
    //   Cookies.remove('satoken');
    //   return result;
    //   // location.href = "login";
    //   // this.$router.push('/login');
    // }else{
    //   return result;
    // }
  })
}