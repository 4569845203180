export const barnerImagesData4 =[

  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 2100,
    "height": 197,
    "src": "2023-10-26/0876da4f3ec73ff180fc87b46b6d1bcf4d52030a.png@1c.webp",
    "a": 0
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 1711,
    "height": 197,
    "src": "2023-10-26/4adc24834b64143296d77bc81ccd952a59301b08.png@1c.webp",
    "a": 0.0101597
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      21,
      0
    ],
    "width": 2100,
    "height": 197,
    "src": "2023-10-26/e33992d2d75647af38c50792c199fb9db006786c.png@1c.webp",
    "a": 0.025399099999999997
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -30,
      0
    ],
    "width": 1649,
    "height": 169,
    "src": "2023-10-26/57102cf6e2e2845f2773581928c1d0f0c1985a3e.png@1c.webp",
    "a": 0.043541399999999994
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      360,
      0
    ],
    "width": 90,
    "height": 81,
    "src": "2023-10-26/0f79aa287163a3271c6cb22a73a6dc0fc210ab7c.png@1c.webp",
    "a": 0.043540999999999996
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -240,
      -36
    ],
    "width": 84,
    "height": 29,
    "src": "2023-10-26/639de634f90688e86b7bd32924cfb029a316cde4.png@1c.webp",
    "a": 0.43541399999999997
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      140,
      0
    ],
    "width": 544,
    "height": 197,
    "src": "2023-10-26/852c3bd9d9608ceccb7265de8cea7cf3a4b039eb.png@1c.webp",
    "a": -0.1523948
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 227,
    "height": 197,
    "src": "2023-10-26/9556f65510f73c44aa6756ee9b8cf7fc2562b906.png@1c.webp",
    "a": 0.253991
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -90,
      12
    ],
    "width": 97,
    "height": 108,
    "src": "2023-10-26/530a4624fe408881101885c6f24c72e8c2a14fb6.png@1c.webp",
    "a": 0.08708273
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -350,
      56
    ],
    "width": 1713,
    "height": 74,
    "src": "2023-10-26/bf896616dec163402ec79a81f936af67932eca2f.png@1c.webp",
    "a": 0.126996
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -165,
      0
    ],
    "width": 180,
    "height": 147,
    "src": "2023-10-26/32aa867cd3e8fba371a2be90d7d844a68c67f7a5.png@1c.webp",
    "a": 0.11973879999999999
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -360,
      12
    ],
    "width": 207,
    "height": 161,
    "src": "2023-10-26/c6263485a0218c887848c47a0fe4f515006e1567.png@1c.webp",
    "a": 0.152395
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -132,
      54
    ],
    "width": 1650,
    "height": 63,
    "src": "2023-10-26/cd4fda9478b06a8d81cd04f69d7a2ad475d1db40.png@1c.webp",
    "a": 0.1741655
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      180,
      12
    ],
    "width": 257,
    "height": 161,
    "src": "2023-10-26/0479542df6a7c5deddf60fbbf63b6e1f1f770c08.png@1c.webp",
    "a": 0.21770699999999998
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -85,
      -4.25
    ],
    "width": 2550,
    "height": 239,
    "src": "2023-10-26/b6f4359409b4550fb38594d8c227902db29c720a.png@1c.webp",
    "a": 0.370102
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -1440,
      0
    ],
    "width": 501,
    "height": 225,
    "src": "2023-10-26/8a6899088fc9dcd37a0039884fb77f69dc3b1c30.png@1c.webp",
    "a": 0.5805520000000001
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      1500,
      0
    ],
    "width": 409,
    "height": 169,
    "src": "2023-10-26/96704a6197153e1717a910e6c227937f34743cee.png@1c.webp",
    "a": 0.6531199999999999
  }
]