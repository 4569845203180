import * as PIXI from 'pixi.js'
window.PIXI = PIXI
const {Live2DModel, Cubism2ModelSettings} = require('pixi-live2d-display')

//模型
let model
//口型标识
let playing = false;
//语音配置
const audioCtx = new AudioContext();
const analyser = audioCtx.createAnalyser();
const frequencyData = new Uint8Array(analyser.frequencyBinCount);
const o = 300;
let websocket = null;
let user = null;
let voiceName = null;
let voiceflag = false;
let originalExp = "original";
//暴露的函数
export async function initModel(url,scale,width,height,isVoice){
    const app = new PIXI.Application({
        view:document.getElementById("live2d"),
        autoStart:true,
        // width:window.innerWidth,
        // height:window.innerHeight,
        width:width,
        height:height,
        transparent:true
    })
    //初始化加载model
    model = await Live2DModel.from(url);
    model.expression(originalExp);
    app.stage.addChild(model);
    model.scale.set(scale);
    voiceflag = isVoice;
    // model.x = window.innerWidth-width;
    // model.y = window.innerHeight-height;
    // 绑定模型拖拽方法
//   draggable(model);
    // doExpression("水印");
    //初始化加载默认点击消息
    // if(msg!=null){
    //     initMessage(msg);
    // }
}
export function doExpression(name,timeout){
    model.expression(name);
    window.setTimeout(model.expression(originalExp), timeout);
}
export function doMotion(name){
    model.motion(name);
    showMessage(name,5000);
}
export function doSpeak(message,time,expression){
    if(expression!=null){
        model.expression(expression);
    }
    showMessage(message,time);
}

export function doVoice(data){
    if(playing){
        return;
    }
    if(data.expression!=null){
        model.expression(data.expression);
    }else{
        model.expression(originalExp);
    }
    if(data.voice!=null){
        showMessageWithVoice(data.message);
        //语音
        var arraybuff = window.atob(data.voice);
        var len = arraybuff.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = arraybuff.charCodeAt(i);
        }
        audioCtx.decodeAudioData(bytes.buffer, function(buffer) {        
            // 新建 Buffer 源
            const source = audioCtx.createBufferSource();
            source.buffer = buffer;
            // 连接到 audioCtx
            source.connect(audioCtx.destination);
            // 连接到 音频分析器
            source.connect(analyser);
            // 开始播放
            source.start(0);
            playing = true;
            run();
            source.onended = ()=>{
                // 停止播放
                playing = false;
                hideMessageWithVoice();
                if(data.expression!=null){
                    model.expression(originalExp);
                }
                // model.removeItem
            }
            
        });
    }else if(data.message!=null){
        showMessage(data.message,5000);
        if(data.expression!=null){
            window.setTimeout(model.expression(originalExp), timeout);
        }
    }   
}

//文本消息使用到的函数
String.prototype.render = function (context) {
    var tokenReg = /(\\)?\{([^\{\}\\]+)(\\)?\}/g;

    return this.replace(tokenReg, function (word, slash1, token, slash2) {
        if (slash1 || slash2) {  
            return word.replace('\\', '');
        }

        var variables = token.replace(/\s/g, '').split('.');
        var currentObject = context;
        var i, length, variable;

        for (i = 0, length = variables.length; i < length; ++i) {
            variable = variables[i];
            currentObject = currentObject[variable];
            if (currentObject === undefined || currentObject === null) return '';
        }
        return currentObject;
    });
};


function showMessage(text, timeout, flag){
    if(flag || sessionStorage.getItem('message-text') === '' || sessionStorage.getItem('message-text') === null){
        if(Array.isArray(text)) text = text[Math.floor(Math.random() * text.length + 1)-1];
        if(flag) sessionStorage.setItem('message-text', text);
        
        $('.message').stop();
        $('.message').html(text).fadeTo(200, 1);
        if (timeout === undefined) timeout = 5000;
        $('.chat-div').fadeTo(200, 0);
        hideMessage(timeout);
    }
}

function hideMessage(timeout){
    $('.message').stop().css('opacity',1);
    if (timeout === undefined) timeout = 5000;
    window.setTimeout(function() {sessionStorage.removeItem('message-text')}, timeout);
    $('.message').delay(timeout).fadeTo(200, 0);
    $('.chat-div').delay(timeout).fadeTo(200, 1);
    model.expression();

}

function showMessageWithVoice(text,flag){
    if(flag || sessionStorage.getItem('message-text') === '' || sessionStorage.getItem('message-text') === null){
        if(Array.isArray(text)) text = text[Math.floor(Math.random() * text.length + 1)-1];
        if(flag) sessionStorage.setItem('message-text', text);
        
        $('.message').stop();
        $('.message').html(text).fadeTo(200, 1);
        $('.chat-div').fadeTo(200, 0);
    }
}

function hideMessageWithVoice(){
    $('.message').stop().css('opacity',1);
    sessionStorage.removeItem('message-text')
    $('.message').fadeTo(200, 0);
    $('.chat-div').fadeTo(200, 1);
}



//语音使用到的函数
function getByteFrequencyData(){
	analyser.getByteFrequencyData(frequencyData);
	return frequencyData;
};
function setMouthOpenY(v){
    v = Math.max(0,Math.min(1,v));
    model.internalModel.coreModel.setParameterValueById('ParamMouthOpenY',v);
}
function arrayAdd(a){
    return a.reduce((i,a)=>i+a,0);
}
function run(){
    if(!playing) return;
    const frequencyData = getByteFrequencyData();
    const arr = []; 
    for (var i = 0; i < 700; i += o) {
        arr.push(frequencyData[i]);
    }
    setMouthOpenY((arrayAdd(arr)/arr.length - 20)/60);
    setTimeout(run,1000/30);
}
function draggable(model) {
    model.buttonMode = true;
    model.on("pointerdown", (e) => {
    //   model.dragging = true;
      model._pointerX = e.data.global.x - model.x;
      model._pointerY = e.data.global.y - model.y;
    });
    model.on("pointermove", (e) => {
      if (model.dragging) {
        // model.position.x = e.data.global.x - model._pointerX;
        // model.position.y = e.data.global.y - model._pointerY;
        $('#live2d').stop().css('left',e.data.global.x - model._pointerX);
        $('#live2d').stop().css('bottom',e.data.global.y - model._pointerY);
        $('.message').stop().css('left',model.position.x);
        $('.message').stop().css('bottom',model.position.y+200);
      }
    });
    model.on("pointerupoutside", () => (model.dragging = false));
    model.on("pointerup", () => (model.dragging = false));
  }

    
//初始化函数
export function initMessage(result,isVoice){
    // alert(resu lt)
    $.each(result.mouseover, function (index, tips) {
        $(document).on("mouseover", tips.selector, function () {
            var text = tips.text;
            var no = Math.floor(Math.random() * tips.text.length + 1) - 1;
            if (Array.isArray(tips.text)) text = tips.text[no];
            text = text.render({ text: $(this).text() });
            doVoice({"message":text,"voice":isVoice?tips.voice[no]:null,"expression":tips.expression[no]});
            // showMessage(text, 3000);
        });
    });
    $.each(result.click, function (index, tips) {
        $(document).on("click", tips.selector, function () {
            var text = tips.text;
            var no = Math.floor(Math.random() * tips.text.length + 1) - 1;
            if (Array.isArray(tips.text)) text = tips.text[no];
            text = text.render({ text: $(this).text() });
            doVoice({"message":text,"voice":isVoice?tips.voice[no]:null,"expression":tips.expression[no]});
            // showMessage(text, 3000, true);
        });
    });
}

//初始化函数
export function initSocket(wsUrl,username,voicename){
    if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
    }else{
        // 实例化socket
        websocket = new WebSocket(wsUrl+'?username='+username);
        user = username;
        voiceName = voicename;
        // this.websocket = new WebSocket("ws://127.0.0.1:10100/")
        // 监听socket连接
        websocket.onopen = open;
        // 监听socket错误信息
        websocket.onerror = error;
        // 监听socket消息
        websocket.onmessage = getMessage;
    }
}
function open() {
    console.log("socket连接成功")
}
function error() {
    console.log("连接错误")
}
function getMessage(msg) {
    var msgJson = JSON.parse(msg.data);
    if(msgJson.method == 'sendToUser'){
        getVoice(msgJson);
    }else{
        doVoice(msgJson,null);
    }
}
function getVoice(data){
    data.fromuser = user;
    data.voiceName = voiceName;
    data.isVoice = voiceflag;
    data.method = "speak";
    websocket.send(JSON.stringify(data))

}
// function close() {
//     console.log("socket已经关闭")
// }