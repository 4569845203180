import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/main',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
  }
]

const router = new VueRouter({
  // mode: 'history', // 去掉url中的#
  routes
})
// 在router/index.js中添加路由守卫
router.beforeEach((to, from, next)=> {
  //未登录
  if(localStorage.getItem('satoken')==null){
    if(to.path != '/login'){
      next({ path: '/login' })
    }else{
      next()
    }
  }else{
    if(to.path == '/login'){
      next()
    }else if(to.path != '/main'){
      next({ path: '/main' })
    }else{
      next();
    }
  }
})
export default router
