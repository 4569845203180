<template>
    
    <div class="login-div">
	    <div id="loader-wrapper" v-show="show">
		    <div id="loader"></div>
		    <div class="loader-section section-left"></div>
		    <div class="loader-section section-right"></div>
		    <div class="load_title">正在加载用户信息，请耐心等待</div>
        </div>
        <!-- <img src="../assets/img/bg1.jpg" style="width:100%;height: 100%;"/> -->
        <Particles/>
        <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer" v-loading="loading">
            <h1 class="loginTitle">
                <span>{{ title }}</span>
            </h1>
            <el-form-item prop="username">
                <el-input type="text" v-model="loginForm.username" placeholder="请输入用户名" @keyup.enter.native="submitLogin">
                    <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password"  v-model="loginForm.password" placeholder="请输入密码" @keyup.enter.native="submitLogin">
                    <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
                </el-input>
            </el-form-item>
            <el-form-item v-if="totpEnabled" prop="totp">
                <el-input  type="text" v-model="loginForm.totp" placeholder="请输入动态密码" @keyup.enter.native="submitLogin">
                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
                </el-input>
            </el-form-item>
            <el-form-item v-if="captchaEnabled" prop="code">
                <el-input type="text" v-model="loginForm.code" placeholder="请输入验证码" style="width: 67%;" @keyup.enter.native="submitLogin">
                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
                </el-input>
                <div class="login-code">
                    <img :src="codeUrl" @click="getCaptcha" class="login-code-img"/>
                </div>
            </el-form-item>
            <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;text-align:left;width: 100%;">记住密码</el-checkbox>
            <!-- <el-checkbox v-model="checked" class="loginRemember">记住我</el-checkbox> -->
            <el-button type="primary" style="width:100%" @click="submitLogin">登录</el-button>
        </el-form>
    </div>
</template>

<script>
import Particles from '../components/particles/index.vue'
import {sendPost} from '@/api/api'
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'

export default {
    data(){
        return{
            loginForm:{
                username:undefined,
                password:undefined,
                rememberMe: false,
                code: "",
                totp: "",
                uuid: "",
                redirect: '',
                mode: "",
                client: "",
            },
            title:process.env.VUE_APP_TITLE,
            show: true,
            loading: false,
            // 验证码开关
            captchaEnabled: true,
            // 动态密码开关
            totpEnabled: true,
            codeUrl: "",
            rules:{
                username:[{required:true,message:"请输入用户名",trigger:"blur"}],
                password:[{required:true,message:"请输入密码",trigger:"blur"}],
                totp:[{required:true,message:"请输入动态密码",trigger:"blur"},{ min: 6, max: 6, message: '动态密码长度为6位', trigger: 'blur' }],
                code:[{required:true,message:"请输入验证码",trigger:"blur"}]
            }

        }
    },
    created() {
        this.preLogin();
        this.getCookie();
    },
    
    methods:{
      submitLogin(){
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;                    
                    if (this.loginForm.rememberMe) {
                        Cookies.set("username", this.loginForm.username, { expires: 30 });
                        Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
                        Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
                    } else {
                        Cookies.remove("username");
                        Cookies.remove("password");
                        Cookies.remove('rememberMe');
                    }
                    sendPost(this.loginForm,"/sso/doLogin").then((res) => {
                        if(res.data.code == '200'){
                            localStorage.setItem('satoken', res.data.data.satoken);
                            if(res.data.data.redirect == '/main'){
                                this.$router.push("/main");
                            }else{
                                location.href = decodeURIComponent(res.data.data.redirect);
                            }
                        }else{
                            this.loading = false;
                            this.$message.error(res.data.msg);
                            this.getCaptcha();
                        }
                    })
                } else {
                    return false;
                }
          });
      },
      getCookie() {
        const username = Cookies.get("username");
        const password = Cookies.get("password");
        const rememberMe = Cookies.get('rememberMe')
        this.loginForm.username = username === undefined ? this.loginForm.username : username;
        this.loginForm.password = password === undefined ? this.loginForm.password : decrypt(password);
        this.loginForm.rememberMe = rememberMe === undefined ? false : Boolean(rememberMe);
      },
      preLogin(){
        const query = this.$route.query;
        if (query && typeof query === 'object') {
            this.loginForm.redirect = query.redirect || '';
        }
        sendPost(this.loginForm,"/sso/getRedirectUrl").then((res) => {
            if(res.data.code == '200'){
                if(res.data.data == '/main'){
                    this.$router.push("/main");
                }else{
                    location.href = decodeURIComponent(res.data.data);
                }
            }else if(res.data.code == '401'){
                this.totpEnabled = res.data.data.totpEnabled;
                this.captchaEnabled = res.data.data.captchaEnabled;
                this.codeUrl = "data:image/gif;base64," + res.data.data.img;
                this.loginForm.uuid = res.data.data.uuid;
                this.show=false;
            }else{
                this.$router.push("/main");
            }
        })
      },
      getCaptcha(){
        sendPost(null,"/sso/getCaptcha").then((res) => {
            if(res.data.code == '200'){
                this.totpEnabled = res.data.data.totpEnabled;
                this.captchaEnabled = res.data.data.captchaEnabled;
                this.codeUrl = "data:image/gif;base64," + res.data.data.img;
                this.loginForm.uuid = res.data.data.uuid;
                this.show=false;
            }
        })
      }
    },
    components:{
        Particles,
    }
};
</script>

<style>
    .loginContainer{
        border-radius: 15px;
        /* background-clip: padding-box; */
        /* margin: 150px auto; */
        /* margin: auto;  */
        /* width: 350px; */
        padding: 15px 35px 15px 35px;
        background: #f0f8ffb3;
        /* border:1px solid rgb(91, 186, 241); */
        /* box-shadow: 0 0 25px #85d2ff; */
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        z-index: 2;
    }
    .loginTitle{
        margin: 0px auto 48px auto;
        text-align: center;
        font-size: 26px;
        margin-bottom: 30px;
        margin-top: 10px;

    }
    .loginRemember{
        text-align: left;
        margin: 0px 0px 15px 0px;
    }
    .login-code {
        width: 33%;
        height: 38px;
        float: right;
        /* img {
            cursor: pointer;
            vertical-align: middle;
        } */
    }
    .login-code-img{
        cursor: pointer;
        vertical-align: middle;
        height: 38px;
    }
    .input-icon {
        height: 39px;
        width: 14px;
        margin-left: 5px;
    }
</style>