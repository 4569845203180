export const barnerImagesData7 =[
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 1728,
    "height": 162,
    "src": "2024-02-01/01b79b5b5b38d7305a0ca1780c24662cc5a59f18.png@1c.webp",
    "a": 0.00272727
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      -9
    ],
    "width": 1728,
    "height": 162,
    "src": "2024-02-01/fe903820ca0f604ef24f16ff7ad51192ead3d0c8.png@1c.webp",
    "a": 0.0109091
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 1728,
    "height": 148,
    "src": "2024-02-01/73fcb8a56776307147c140f68bf9c5793696ae88.png@1c.webp",
    "a": 0.0218182
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      -40
    ],
    "width": 826,
    "height": 76,
    "src": "2024-02-01/b8a5bcf77a07793ce50294a8cb14a9fb8f2cea83.png@1c.webp",
    "a": 0.018181799999999998
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      100,
      0
    ],
    "width": 1523,
    "height": 180,
    "src": "2024-02-01/1fd3d44b8f890b88cf52f6bcc041326b30e7cb62.png@1c.webp",
    "a": 0.03636400000000001
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -126,
      -45
    ],
    "width": 382,
    "height": 67,
    "src": "2024-02-01/085bc5e7f70b625b62c1f5d374f55acbd828ab65.png@1c.webp",
    "a": 0.0272727
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      10
    ],
    "width": 1920,
    "height": 156,
    "src": "2024-02-01/3e16264878dd88c1b8ead37aab07a9363186b18c.png@1c.webp",
    "a": 0.0424242
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      276,
      -48
    ],
    "width": 125,
    "height": 131,
    "src": "2024-02-01/8902051ab71c8093a85ce2a71882054ee91ea631.png@1c.webp",
    "a": 0.05818200000000002,
    "deg": -0.00065
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      660,
      0
    ],
    "width": 589,
    "height": 180,
    "src": "2024-02-01/c9231e763120806f3e918d06ad3f415e920c2b73.png@1c.webp",
    "a": 0.048485000000000014
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      750,
      0
    ],
    "width": 420,
    "height": 157,
    "src": "2024-02-01/67a3c27832d63060d88fbd7bdb2fc543e3fb9ddf.png@1c.webp",
    "a": 0.06060599999999999
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      503.5,
      14.25
    ],
    "width": 370,
    "height": 142,
    "src": "2024-02-01/c651a0183b81857191c86b133c5a777059b13571.png@1c.webp",
    "a": 0.06909100000000001
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      504,
      13.5
    ],
    "width": 172,
    "height": 133,
    "src": "2024-02-01/6afc90f36610318b4785a52c1a51ef6f1de9d25b.png@1c.webp",
    "a": 0.14727300000000002
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -171,
      -4.5
    ],
    "width": 203,
    "height": 149,
    "src": "2024-02-01/476679726643b3b6cc5599b882d85953ef34f3d9.png@1c.webp",
    "a": 0.054545
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -540,
      0
    ],
    "width": 732,
    "height": 162,
    "src": "2024-02-01/121335f79c78bb6f47e4fc7dae4b0af0eac804bb.png@1c.webp",
    "a": 0.09818200000000002
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -332.5,
      23.75
    ],
    "width": 292,
    "height": 112,
    "src": "2024-02-01/590a6dd64ff00d5d0e63e230188939c405253452.png@1c.webp",
    "a": 0.08636400000000001
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -551,
      23.75
    ],
    "width": 191,
    "height": 116,
    "src": "2024-02-01/e00c60ae700774655af2e2516a13a5b1fa2fcfe7.png@1c.webp",
    "a": 0.14451499999999998
  },
  {
    "tagName": "img",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      -880,
      16
    ],
    "width": 379,
    "height": 144,
    "src": "2024-02-01/357dd346995f678c43eb6bf51fe02f47bbe7db7d.png@1c.webp",
    "a": 0.17454499999999995
  },
  {
    "tagName": "img",
    "opacity": [
      "0.3",
      "0.3"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      20,
      10
    ],
    "width": 288,
    "height": 145,
    "src": "2024-02-01/bae427c01d895b63e6aaf6b0c239b86027527e6a.png@1c.webp",
    "a": 0.04848480000000001
  },
  {
    "tagName": "video",
    "opacity": [
      "1",
      "1"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      0,
      0
    ],
    "width": 2112,
    "height": 154,
    "src": "2024-02-01/a98a8707-3a16-4700-949b-57a2f9693deb.mp4",
    "a": 0.02
  },
  {
    "tagName": "video",
    "opacity": [
      "0.3",
      "0.3"
    ],
    "transform": [
      1,
      0,
      0,
      1,
      42,
      -84
    ],
    "width": 420,
    "height": 630,
    "src": "2024-02-01/83d47d6f-2598-454f-9ffa-495ee5f3d270.mp4",
    "a": 0.0458182
  }
]